import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { BasicPageHeading } from '../../components/PageHeading';
import theme from '../../data/theme';
import { TextSection, SectionHeading } from '../../components/textComponents';

import { whenTablet } from '../../utils/responsive';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Landscaping job',
  'Landscaping company',
  'job',
  'jobs',
  'careers',
  'open positions',
  'Ohio',
  'Avon',
  'Avon Lake',
  'Bay Village',
  'Westlake',
  'North Ridgeville',
  'Elyria'
];

const page = {
  title: 'Careers',
  keywords: keywords,
};

const Wrapper = styled.div`
  width: ${ theme.contentWidth };
  max-width: ${ theme.maxContentWidth };
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;

const LeftContent = styled.div`
  flex: 3;
  min-width: 30rem;
  margin-right: 2rem;
  margin-bottom: 5rem;

  ${ whenTablet(css`
    margin-right: 4rem;
  `) };
`;

const RightContent = styled.div`
  flex: 1;
  min-width: 26rem;
`;

const Position = styled.div`
  margin-bottom: 8rem;

  &:last-of-type {
    margin-bottom: 0;
  };
`;

const JobTitle = styled.h3`
  font-size: 2.0rem;
`;

const SubHeading = styled.h4`
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 3rem;
`;

const PositionDetail = styled.p`
  font-size: 1.6rem;
`;

const ActionButton = styled.a`
  display: block;
  text-align: center;
  text-decoration: none;
  border: 0;
  background-color: ${ theme.colors.primary };
  color: ${ theme.colors.white };
  font-size: 1.8rem;
  font-weight: 600;
  padding: 2rem;
  width: 26rem;
  margin-bottom: 3rem;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${ theme.colors.darkPrimary };
  }
`;

export default () => (
  <Layout>
    <SEO title={page.title} keywords={page.keywords} />
    <BasicPageHeading page={page}/>
    <Wrapper>
      <LeftContent>
        <TextSection>
          <SectionHeading css={`
            margin-bottom: 4rem;
          `}>Open Positions</SectionHeading>
          <Position>
            <JobTitle>LANDSCAPING FOREMAN</JobTitle>

            <SubHeading>Job Description</SubHeading>
            <PositionDetail>
              This position includes landscaping installs and doing spring cleanups, edging, mulching, and trimming bushes.
            </PositionDetail>
            <PositionDetail>
              Use of machinery such as string trimmers, blowers, and commercial mowers
            </PositionDetail>
            <PositionDetail>
              Snow removal in the winter (must be able to operate a truck and snow plow).
            </PositionDetail>

            <SubHeading>Job Requirements</SubHeading>
            <PositionDetail>
              Must have at least 5 years’ experience running a landscaping crew doing commercial accts as well as high end residential accts.
            </PositionDetail>
            <PositionDetail>
              Must exhibit good attendence, and work well with others
            </PositionDetail>
            <PositionDetail>
              Must have valid driver’s license with a clean record.
            </PositionDetail>

            <SubHeading>Job Details</SubHeading>
            <PositionDetail>
              Job Type: Full-time
            </PositionDetail>
          </Position>
          <Position>
            <JobTitle>CREW MEMBER</JobTitle>

            <SubHeading>Job Description</SubHeading>
            <PositionDetail>
              This position includes some landscaping installs and doing spring cleanups, fall cleanups, edging, mulching, and trimming bushes.
            </PositionDetail>
            <PositionDetail>
              Snow removal in the winter - must be able to work at all times of the day/night
            </PositionDetail>

            <SubHeading>Job Requirements</SubHeading>
            <PositionDetail>
              Must be able to mow on riders and use a weed eater for edging beds and using backpack blowers.
            </PositionDetail>
            <PositionDetail>
              Must exhibit good attendence, and work well with others
            </PositionDetail>
            <PositionDetail>
              Must be physically fit and have the ability to lift 50lbs or more.
            </PositionDetail>
            <PositionDetail>
              Must have valid driver’s license with a clean record.
            </PositionDetail>

            <SubHeading>Job Details</SubHeading>
            <PositionDetail>
              Job Type: Full-time
            </PositionDetail>
          </Position>
        </TextSection>
      </LeftContent>
      <RightContent>
        <ActionButton href="/assets/VLP_Landscaping_Application.pdf" target="_blank">Download Application</ActionButton>
        <ActionButton as={Link} to="/careers/apply/">Submit Application</ActionButton>
      </RightContent>
    </Wrapper>
  </Layout>
);
